<template>
	<div class="pricing-faq" id="faq">
		<div :class="[ $mq.below(960) ? 'container-section-mobile' : 'container-section']">
			<div class="section-title">Întrebări Frecvente</div>
			<div class="section-description">Ai o nedumerire despre achiziția abonamentului TBF Digital? Mai jos găsești răspunsuri rapide la întrebările frecvente pe care le primim de la clienții nostri.</div>

			<div class="list-faqs">
				<div class="question-box" id="question1">
					<div class="header">
						<div class="title">Cum plătesc abonamentul?</div>
						<div class="btn-collapse" @click="openQuestion(1)"><plus-collapse /></div>
					</div>
					<div class="answer">
						<p>Abonamentul se plătește online, prin card. După ce ți-ai ales abonamentul potrivit, din lista de mai sus, urmează pașii pentru a-ți crea contul și pentru a efectua plata online.</p>
					</div>
				</div>
				<div class="question-box" id="question2">
					<div class="header">
						<div class="title">Pot să plătesc și prin transfer bancar?</div>
						<div class="btn-collapse" @click="openQuestion(2)"><plus-collapse /></div>
					</div>
					<div class="answer">
						<p>Singura modalitate în care poți să ai acces la TBF Digital este prin plata online, cu cardul. Folosim ca procesator de carduri Stripe.com care este cel mai mare, în acest moment, la nivel mondial. Noi nu stocăm niciodată datele cardului tău așa că nu ai nevoie să îți faci probleme de siguranță.</p>
					</div>
				</div>
				<div class="question-box" id="question3">
					<div class="header">
						<div class="title">Cum primesc factura?</div>
						<div class="btn-collapse" @click="openQuestion(3)"><plus-collapse /></div>
					</div>
					<div class="answer">
						<p>Factura este emisă în mod automat cu datele completate de tine în procesul de creare de cont. Poți alege dacă vrei factura pe firmă sau pe persoană fizică. Fiecare factură este trimisă automat pe email la momentul emiterii. Poți accesa toate facturile și direct din TBF Digital. În plus, poți stabili alte adrese de email la care să se trimită facturile în mod automat. Vei găsi toate aceste informații în articolele de suport.</p>
					</div>
				</div>
				<div class="question-box" id="question4">
					<div class="header">
						<div class="title">Când primesc acces la articolele de suport?</div>
						<div class="btn-collapse" @click="openQuestion(4)"><plus-collapse /></div>
					</div>
					<div class="answer">
						<p>Imediat după ce ți-ai creat contul și ai efectuat plata. Acest lucru înseamnă maximum 5 minute. În articolele de suport nu găsești doar informații despre cum să folosești softul, ci și instrucțiuni detaliate de management, recrutări și organizare a companiei.</p>
					</div>
				</div>
				<div class="question-box" id="question5">
					<div class="header">
						<div class="title">Cum învăț să folosesc sistemul TBF și platforma TBF Digital?</div>
						<div class="btn-collapse" @click="openQuestion(5)"><plus-collapse /></div>
					</div>
					<div class="answer">
						<p>Articolele de suport, la care vei primi acces imediat după ce ți-ai creat contul, te vor ghida pe tine și pe colegii tăi prin toate detaliile necesare folosirii softului. Nu va trebui să ghicești sau să pierzi timp descoperind cum funcționează sistemul și softul.</p>
					</div>
				</div>
<!-- 				<div class="question-box" id="question6">
					<div class="header">
						<div class="title">Cum implementez TBF Digital în compania mea?</div>
						<div class="btn-collapse" @click="openQuestion(6)"><plus-collapse /></div>
					</div>
					<div class="answer">
						<p>Imediat după ce ți-ai creat contul TBF Digital și ai efectuat plata abonamentului, vei primi un ghid complet de implementare a platformei. În acest ghid vei înțelege ce sunt obiectivele și rezultatele cheie, cum să le folosești, cum să le transmiți echipei și absolut toate informațiile de care ai nevoie pentru a implementa corect acest sistem de management. Mai mult, vei avea acces la grupul privat de Facebook unde Răzvan Căzănescu, fondatorul TBF, îți va răspunde personal la întrebări și nelămuriri.</p>
					</div>
				</div> -->
				<div class="question-box" id="question7">
					<div class="header">
						<div class="title">Cum știu dacă se potrivește acest sistem pentru compania mea?</div>
						<div class="btn-collapse" @click="openQuestion(7)"><plus-collapse /></div>
					</div>
					<div class="answer">
						<p>Răzvan Căzănescu, fondatorul TBF, a crescut peste 300 de companii din 23 de industrii folosind acest sistem. De la startupuri cu 3-4 colegi până la corporații cu mii de colegi. Sistemul se bazează pe lucruri fundamentale care nu se schimbă de la o companie la alta. În orice afacere ai nevoie de lideri care să știe clar ce să obțină, să rămână focalizați și să măsoare constant progresul. În orice afacere ai nevoie ca oamenii tăi să își stabilească prioritățile zilnice și să lucreze la lucrurile importante. Acest lucru îl face TBF Digital, într-un mod elegant, simplu si extrem de puternic.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import PlusCollapse from '../Icons/PlusCollapse'
	import { scrollTo } from 'vue-scrollto'

	export default {
		components: {
			PlusCollapse
		},
		data() {
			return {
				
			}
		},
		mounted(){
			if(this.$route.hash == "#faq"){
				setTimeout(() => this.scrollMeTo(this.$route.hash), 1);
			}
		},
		methods: {
			scrollMeTo(hashbang) {
				scrollTo(hashbang, 500, { easing: 'ease-in-out' })
			},
			openQuestion(id){
				var element = document.querySelector('#question' + id + '')

				if(element.classList.contains("active")){
					document.querySelector('#question'+ id +' .answer').classList.remove("show-text");
					setTimeout(() => {
						element.classList.remove("active");
					}, 200)
				}else{
					element.classList.add("active");
					setTimeout(() => {
						document.querySelector('#question' + id + ' .answer').classList.add("show-text");
					}, 200)
				}
			}
		}
	}
</script>

<style lang="scss">
	// @import "../../styles/sections/faq.scss";
</style>